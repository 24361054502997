import Lightbox from 'react-image-lightbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

// This ordering was generated by the previous lightbox library we were using
// here. If we wanted to change this (i.e. add/remove images), you'd have to
// manually edit the layout.
const imagesIndices = [0, 7, 10, 1, 4, 11, 14, 2, 8, 12, 3, 5, 6, 9, 13];

const Photos = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [sourceIndex, setSourceIndex] = useState(0);

  return (
    <>
      <div className="jumbotron" id="photos">
        <div className="inner">
          <h1>Photos</h1>
        </div>
      </div>
      <div className="container centered">
        <h1>Gallery</h1>
        <div className="gallery">
          {imagesIndices.map((index) => {
            return (
              <div className="item" key={index}>
                <a href="#" onClick={() => {
                  setSourceIndex(index);
                  setIsOpen((prev) => !prev);
                }}>
                  <img alt="Gallery image" src={`/src/assets/images/gallery/${index}_t.jpg`} />
                  <div className="overlay"><FontAwesomeIcon icon={faSearchPlus} /></div>
                </a>
              </div>
            );
          })}
          {isOpen && (
            <Lightbox
              imagePadding={64}
              mainSrc={`/src/assets/images/gallery/${sourceIndex}.jpg`}
              mainSrcThumbnail={`/src/assets/images/gallery/${sourceIndex}_t.jpg`}
              nextSrc={`/src/assets/images/gallery/${(sourceIndex + 1) % imagesIndices.length}.jpg`}
              nextSrcThumbnail={`/src/assets/images/gallery/${(sourceIndex + 1) % imagesIndices.length}_t.jpg`}
              onCloseRequest={() => setIsOpen((prev) => !prev)}
              onMoveNextRequest={() => setSourceIndex((prev) => (prev + 1) % imagesIndices.length)}
              onMovePrevRequest={() => setSourceIndex((prev) => (prev + imagesIndices.length - 1) % imagesIndices.length)}
              prevSrc={`/src/assets/images/gallery/${(sourceIndex + imagesIndices.length - 1) % imagesIndices.length}.jpg`}
              prevSrcThumbnail={`/src/assets/images/gallery/${(sourceIndex + imagesIndices.length - 1) % imagesIndices.length}_t.jpg`}
              wrapperClassName="gallery-modal"
            />
          )}
        </div>
        <p>Photos by <a href="http://www.georgestreetphoto.com" rel="noreferrer" target="_blank">George Street Photo &amp; Video</a></p>
      </div>
    </>
  );
};

export default Photos;
